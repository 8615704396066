import React from 'react'
import styled from 'styled-components'

import Slider from '../Slider'
import Label from '../Label'
import title3Styles from '../../styles/title3'
import backgroundImg from '../../images/testimonials.jpg'

const Wrapper = styled.div`
  background: url(${backgroundImg}) center center no-repeat;
  background-size: cover;
  padding: 3rem 0;
  color: #fff;
`

const Box = styled.div``

const Title = styled.div`
  margin: 0 auto;
  position: relative;
  text-align: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  &::after {
    content: '';
    height: 2px;
    background: #fff;
    width: 168px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Content = styled.div`
  ${title3Styles}
  text-align: center;
  max-width: 100%;
  width: 80%;
  margin: 4rem auto;

  @media (max-width: 480px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Testimonial = ({ title, content }) => (
  <Box>
    <Title>
      <Label>{title}</Label>
    </Title>
    <Content>{content}</Content>
  </Box>
)

const Testimonials = ({ testimonials }) => {
  const slides = testimonials.map(testimonial => {
    return (
      <Testimonial
        title={testimonial.title}
        content={testimonial.content.content}
      />
    )
  })

  return (
    <Wrapper>
      <Slider
        slides={slides}
        design="white"
        options={{ adaptiveHeight: false }}
      />
    </Wrapper>
  )
}

export default Testimonials
