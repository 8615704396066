import React, { useState } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { format as formatDate } from 'date-fns'
import { FormattedMessage, useIntl } from 'react-intl'

import Label from '../Label'
import Box from '../Box'
import More from '../More'
import Title from '../Title'

const Wrapper = styled.div``

const Columns = styled.div`
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Column = styled.div`
  flex-basis: 33.333333%;
  padding: 10px;
  display: flex;

  @media (max-width: 860px) {
    flex-basis: 100%;
  }
`

const StyledBox = styled(Box)`
  text-align: center;
  width: 100%;
`

const Content = styled.div`
  a {
    text-decoration: none;
  }
`

const Hidden = styled(AnimateHeight)`
  overflow: hidden;
`

const renderLink = publication => {
  const { title } = publication.title

  if (publication.link) {
    return (
      <a href={publication.link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    )
  }

  if (
    publication.document &&
    publication.document.file &&
    publication.document.file.url
  ) {
    return (
      <a
        href={publication.document.file.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    )
  }

  return title
}

const Publication = ({ publication, ...props }) => (
  <Column {...props}>
    <StyledBox>
      <Label>{formatDate(new Date(publication.date), 'yyyy')}</Label>
      <Content>{publication.authors}</Content>
      <Content>{renderLink(publication)}</Content>
    </StyledBox>
  </Column>
)

const Publications = ({ publications }) => {
  const intl = useIntl()
  const [isShowMore, setShowMore] = useState(false)

  const sortedPublications = publications.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  const featuredPublications = sortedPublications.slice(0, 3)
  const othersPublications = sortedPublications.slice(3)

  return (
    <Wrapper>
      <Title size="small">
        <FormattedMessage id="team.publications" />
      </Title>
      <Columns>
        {featuredPublications.map((publication, index) => (
          <Publication publication={publication} key={index} />
        ))}
      </Columns>
      <Hidden duration={500} height={isShowMore ? 'auto' : 0}>
        <Columns style={{ marginTop: '10px' }}>
          {othersPublications.map((publication, index) => (
            <Publication publication={publication} key={index} />
          ))}
        </Columns>
      </Hidden>
      <br />
      {!isShowMore && publications.length > 3 && (
        <More
          label={intl.formatMessage({ id: 'more' })}
          onClick={() => setShowMore(true)}
        />
      )}
    </Wrapper>
  )
}

export default Publications
