import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 1.5rem;
  text-align: center;
  padding-right: 12px;
  padding-left: 12px;

  @media (min-width: 680px) {
    a {
      width: 33.333333%;
    }
  }
`

const Action = ({ children }) => <Wrapper>{children}</Wrapper>

export default Action
