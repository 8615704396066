import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import labelStyles from '../styles/label'

const Button = styled(Link)`
  ${labelStyles}
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  display: inline-block;
  padding: 1rem 2rem;
  text-decoration: none;
  text-align: center;
  /* width: calc(33.333333% - 20px); */
  max-width: 100%;
  transition: background 0.35s ease, color 0.35s ease;
  cursor: pointer;
  background: transparent;

  &:hover {
    background: ${props => props.theme.colors.primary};
    color: #fff;
  }

  ${props =>
    props.fullWidth &&
    css`
      display: block;
    `}
`

export default Button
