import React from 'react'
import styled, { css } from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FormattedMessage, useIntl } from 'react-intl'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Label from '../components/Label'
import Action from '../components/Action'
import Button from '../components/Button'
import Testimonials from '../components/Team/Testimonials'
import Awards from '../components/Team/Awards'
import Publications from '../components/Team/Publications'

const Meta = styled.div`
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  margin-top: -1.75rem;
  margin-bottom: 3rem;

  a {
    text-decoration: none;
  }
`

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* Fix weird bug in IE 11 */
    flex-wrap: nowrap;
  }
`

const Column = styled.div`
  flex: auto;
  padding-left: 10px;
  padding-right: 10px;
  flex-basis: ${props => (100 / 12) * props.width}%;

  ${props =>
    props.padding &&
    css`
      @media (min-width: 681px) {
        padding-left: 2.5rem;
      }
    `}

  @media (max-width: 1280px) {
    ${props =>
      props.width === 6 &&
      css`
        flex-basis: 100%;
        margin-bottom: 1.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      `}

    ${props =>
      props.width === 4 ||
      (props.width === 8 &&
        css`
          flex-basis: 50%;
        `)}
  }

  @media (max-width: 680px) {
    flex-basis: 100%;
    margin-bottom: 1.5rem;
  }
`

const StyledLabel = styled(Label)`
  margin-bottom: 0.2rem;
`

const RichText = styled.div`
  ul {
    margin: 0;
  }
`

const PhotoImg = styled(Img)``

function getPhotoAsset(photo) {
  return photo.fluid
}

const generateListFromProperty = (array, property) => {
  return array.map(e => e[property]).join(', ')
}

const options = {
  renderText: text => {
    if (!text) {
      return ''
    }

    if (typeof text === 'string' || text instanceof String) {
      return text
        .split('\n')
        .flatMap((txt, i) => [i > 0 && <br key={i} />, txt])
    }

    return text
  },
}

const TeamDetailsTemplate = ({ data }) => {
  const {
    name,
    admitted,
    status,
    phone,
    email,
    practice,
    notary,
    languages,
    education,
    memberships,
    awards,
    testimonials,
    publication,
    photo,
    photoDetails,
    gender,
  } = data.allContentfulTeam.edges[0].node

  const intl = useIntl()

  return (
    <Layout>
      <SEO title={name} />
      <Content intro>
        <Title top="Team">{name}</Title>
        <Meta>
          {status.title}
          <br />
          <a href={`mailto:${email}`}>{email}</a>
          <br />
          <FormattedMessage id="contact.phone" />{' '}
          <a href={`tel:${phone}`}>{phone}</a>
        </Meta>
        <Columns>
          <Column width={4}>
            <PhotoImg fluid={getPhotoAsset(photo, photoDetails)} />
          </Column>
          <Column width={8} padding>
            <Columns>
              <Column width={6}>
                {admitted && (
                  <>
                    <StyledLabel>
                      {gender === 'FEMALE' ? (
                        <FormattedMessage id="team.admitted.female" />
                      ) : (
                        <FormattedMessage id="team.admitted" />
                      )}
                    </StyledLabel>
                    {documentToReactComponents(admitted.json, options)}
                    <br />
                  </>
                )}
                {notary && (
                  <>
                    <StyledLabel>
                      <FormattedMessage id="team.notary" />
                    </StyledLabel>
                    <RichText>
                      {documentToReactComponents(notary.json, options)}
                    </RichText>
                    <br />
                  </>
                )}
                {practice && (
                  <>
                    <StyledLabel>
                      <FormattedMessage id="team.practice" />
                    </StyledLabel>
                    <RichText>
                      {documentToReactComponents(practice.json, options)}
                    </RichText>
                  </>
                )}
                {languages && (
                  <>
                    <br />
                    <StyledLabel>
                      <FormattedMessage id="team.languages" />
                    </StyledLabel>
                    {generateListFromProperty(languages, 'language')}
                  </>
                )}
                {awards && (
                  <>
                    <br />
                    <br />
                    <Awards awards={awards} />
                  </>
                )}
              </Column>
              <Column width={6}>
                {education && (
                  <>
                    <StyledLabel>
                      <FormattedMessage id="team.education" />{' '}
                      {name === 'Gerhard Mislik'
                        ? `& ${intl.formatMessage({ id: 'team.career' })}`
                        : ''}
                    </StyledLabel>
                    <RichText>
                      {documentToReactComponents(education.json, options)}
                    </RichText>
                    <br />
                  </>
                )}
                {memberships && (
                  <>
                    <StyledLabel>
                      <FormattedMessage id="team.memberships" />
                    </StyledLabel>
                    <RichText>
                      {documentToReactComponents(memberships.json, options)}
                    </RichText>
                  </>
                )}
              </Column>
            </Columns>
          </Column>
        </Columns>
        <br />
        <br />
        <br />
        {testimonials && (
          <>
            <Testimonials testimonials={testimonials} showNext />
            <br />
            <br />
          </>
        )}
        <Spacer size="small" />
        {publication && (
          <>
            <Publications publications={publication} />
            <Spacer size="medium" />
          </>
        )}
        <Action>
          <Button to={`/${intl.locale}/team`}>
            <FormattedMessage id="team.overview" />
          </Button>
        </Action>
        <Spacer size="medium" />
      </Content>
    </Layout>
  )
}

export default TeamDetailsTemplate

export const query = graphql`
  query($slug: String!, $node_locale: String!) {
    allContentfulTeam(
      filter: { slug: { eq: $slug }, node_locale: { eq: $node_locale } }
    ) {
      edges {
        node {
          id
          contentful_id
          node_locale
          name
          slug
          photo {
            fixed(width: 456, quality: 100) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 456, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photoDetails
          status {
            title
          }
          gender
          email
          phone
          admitted {
            json
          }
          notary {
            json
          }
          practice {
            json
          }
          education {
            json
          }
          memberships {
            json
          }
          languages {
            language
          }
          awards {
            fixed {
              src
            }
          }
          testimonials {
            title
            content {
              content
            }
          }
          publication {
            date
            authors
            title {
              title
            }
            link
            document {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
