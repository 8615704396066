import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  list-style: none;
  margin: -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.li`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
`

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`

const Awards = ({ awards }) => {
  return (
    <List>
      {awards.map((award, index) => {
        if (!award || !award.fixed) {
          return null
        }

        return (
          <Item key={index}>
            <Img src={award.fixed.src} alt="" />
          </Item>
        )
      })}
    </List>
  )
}

export default Awards
